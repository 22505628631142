<div [formGroup]="fluidFormGroup" class="ui-table">
  <table>
    <thead>
      <tr>
        <th class="col-10 text-align-right">Request ID / </th>
        <th class="col-10">Slurry #</th>
        <th class="col-15">Name</th>
        <th class="col-15">Test Type</th>
        <th class="col-15">Slurry Type</th>
        <th class="col-5">Usage Flag</th>
        <th class="col-30">Test Status</th>
      </tr>
    </thead>
    <tbody>
      <tr class="fluid-event-body">
        <td class="request-id">
          <span>{{fluidFormGroup?.controls?.requestId.value ? fluidFormGroup?.controls?.requestId.value : fluidFormGroup?.controls?.requestIdHDF.value}}</span>
          <br />
          <a *ngIf="fluidFormGroup?.controls?.requestId.value"
             class="ifacts-link"
             href="{{fluidFormGroup?.controls?.requestId.value | iFactsLink:'request'}}" target="_blank">
            (View in iFacts)
          </a>
          <i *ngIf="fluidFormGroup?.controls?.requestIdHDF.value && fluidFormGroup?.controls?.slurrySource.value === slurrySource.HDFFluid"
            class="fa fa-info-circle" title="This is an HDF request ID - not linked to iFacts."></i>
        </td>

        <td [lockableElement]="'job_completed'">
          <p-dropdown-ex formControlName="slurryId" [options]="slurryOptions" (onChange)="_onFluidChange($event.value)">
          </p-dropdown-ex>
        </td>
        <td>
          <div *ngIf="!isMudSlurryType">
            <input
              [lockableElement]="'Fluid_testTypeId'"
              class="input-material"
              (keyup)="changeFluidName($event)"
              type="text"
              [valueToParse]="Name"
              customTooltip
              formControlName="name">
          </div>
          <div *ngIf="isMudSlurryType" class="fluid-name">
            {{Name}}
          </div>
        </td>
        <td [lockableElement]="'Fluid_testTypeId'">
          <p-dropdown [options]="testTypeData$ | async" formControlName="testTypeId" placeholder="&nbsp;"
            ></p-dropdown>
          <error-display formControlName="testTypeId" [errorMsg]="errorMessages.testTypeId"></error-display>
        </td>
        <td [lockableElement]="'Fluid_slurryTypeId'">
          <p-dropdown [options]="slurryTypeData$ | async" formControlName="slurryTypeId"
            placeholder="&nbsp;" ></p-dropdown>
          <error-display formControlName="slurryTypeId" [errorMsg]="errorMessages.slurryTypeId"></error-display>
        </td>
        <td class="primary-status-col">
          <div class="status">
            <i class="primary-status">{{fluidFormGroup?.controls?.primaryStatus.value | primaryStatus}}</i>
          </div>
        </td>
        <td>
          <div class="col-md-9">
            <div class="flx-ctn-row">
              <div class="flx-itm-grow">
                <span class="custom-text">{{fluidFormGroup.controls.testStatus?.value}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-3 float-right">
            <div class="col-md-1 flx-itm-center overflow-menu">
              <ul class="list-inline" *ngIf="!(appStateService.notifyIFactDown$ | async) && canEdit">
                <li class="list-inline-item" [ngClass]="{'disabled': !(copyPresent || createPresent || removePresent)}">
                    <i class="dropdown-toggle fa fa-ellipsis-h fa-2x" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" aria-hidden="true" [ngClass]="{'disabled': !(copyPresent || createPresent || removePresent)}">
                    </i>
                  <ul class="dropdown-menu dropdown-fluid-detail">
                    <li [lockableElement]="'Fluid_remove'"
                      *ngIf="createPresent">
                      <a (click)="createRequest.emit()" class="fluid-menu">
                        <i class="fa fa-plus"></i> Create new iFacts request based on this Fluid
                      </a>
                    </li>
                    <li [lockableElement]="'Fluid_remove'"
                      *ngIf="copyPresent">
                      <a (click)="copyRequest.emit()" class="fluid-menu">
                        <i class="fa fa-files-o"></i> Copy Request
                      </a>
                    </li>
                    <li class="remove-button" [lockableElement]="'Fluid_remove'"
                      *ngIf="removePresent">
                      <a (click)="_deleteFluid()" class="fluid-menu">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div [lockableElement]="'Fluid_remove'" *ngIf="!(appStateService.notifyIFactDown$ | async) && canEdit
                                                         && (fluidFormGroup?.controls?.primaryStatus.value == null || fluidFormGroup?.controls?.primaryStatus.value == 'None')">
            <button class="btn-remove-fluid-event"  (click)="_deleteFluid()">
              <div class="btn-remove-fluid-event-icon">
                <i class="fa fa-times"></i>
              </div>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    </table>
</div>
