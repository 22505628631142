import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, of, Observable, Subscription } from 'rxjs';
import { first, map, shareReplay, switchMap, delay } from 'rxjs/operators';

import { ERROR_TYPE, FLUID_TYPE_SCHEDULE, UnitType } from 'libs/constants';
import { FluidModel, ISAPMaterialModel, Job, PumpScheduleStageMaterialModel, PumpScheduleStageModel } from 'libs/models';
import { ApplicationStateService, ControlPointService2, EventHubService, PumpScheduleStageService } from 'libs/shared/services';
import { ErrorMessageModel, DynamicComponentService, ColumnDefinition } from 'libs/ui';
import { getDisplayValue, UnitConversionService } from 'libs/ui/unit-conversions';
import { ActualQuantityConfirmationComponent } from '../../../sidebar-dialogs/components';
import { ActualQuantityConfirmationService } from '../../../sidebar-dialogs/services';
import { StageStateManager } from '../../state/stage-state-manager';
import { ViewState } from '../../view-state';
import { isPrimitive } from 'libs/helpers/type.helper';
import { PumpScheduleStateFactory } from '../../state/state-factory';
import { PumpScheduleStageStateManager } from '../../models/pump-schedule-stage-state-manager.model';
import { MudParametersComponent } from '../../../pump-schedule/components';
import { ControlPointState } from '../../../shared/constant';
import { ThickeningTimeTestResultComponent } from '../thickening-time-test-result/thickening-time-test-result.component';
import { ControlPointAdapter } from '../../../control-point/adapters/control-point.adapter';
import { cloneDeep, findIndex, isEqual, isNil, uniq } from 'lodash';
import { StageFluidMaterialComponent } from '../stage-fluid-material/stage-fluid-material.component';
import { SupplementalMaterial } from '../../models/supplemental-material.model';
import { PushedBulkPlantInfo } from '../../models/pushed-bulk-plant-info.model';
import { MixProcedures } from '../../../shared/constant/mix-procedures';

@Component({
  selector: 'stage-info',
  templateUrl: './stage-info.component.html',
  styleUrls: ['./stage-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageInfoComponent implements OnInit, OnDestroy {
  private weight;
  private volume;
  private static _cp1Fields: string[] = [
    'avgRate',
    'volume',
    'topOfFluid',
    'fluidName',
    'totalCOGS'
  ];

  private static _cp2Fields: string[] = [
    'avgRate',
    'volume',
    'topOfFluid',
    'dryWeight',
    'dryVolume',
    'fluidName'
  ];

  private static _cp4Fields: string[] = [
    'volume',
    'loadOutQuantity',
    'actualVolumePumped',
    'plannedDensity',
    'actualDensity'
  ];

  private static _scheduleEditFields: string[] = [
    ...StageInfoComponent._cp2Fields,
    'totalCOGS'
  ];

  // array index corresponds to cp number,
  // if 0 - this is schedule edit view
  private static _visibleFields: string[][] = [
    StageInfoComponent._scheduleEditFields,
    StageInfoComponent._cp1Fields,
    StageInfoComponent._cp2Fields,
    [],
    StageInfoComponent._cp4Fields
  ];

  private _filteredFluids$: Observable<FluidModel[]>;

  private readonly _subscriptions = new Subscription();

  private readonly _fluidSearchCompleteSrc = new BehaviorSubject<string>(null);
  @Input() isRequiredShow;
  @Input()
  public stageState: StageStateManager;

  @Input() public isPumpDefault: boolean;

  @Input() public job: Job;
  @Input() jobType: string;
  @Input() controlPointType: number;
  @Input() public hasScope3access: boolean;
  @Input() index: number;
  @Input() isPumpTab = false;

  @Output() onStageAlign = new EventEmitter<any>();

  public UnitType = UnitType;
  public loadingSubscription: Subscription;
  public name: string;
  public pumps: PumpScheduleStageStateManager[] = [];
  private isPlugJobSubject = new BehaviorSubject<boolean>(null);
  public isPlugJob$ = this.isPlugJobSubject.asObservable();
  originalPumpSchedule: any;
  changedPumpSchedule: any
  originalForm: any;
  sapMaterial: PumpScheduleStageMaterialModel
  material: any;
  shownModal = false;
  loadingStatus = null;
  stageLoadoutVolumeChanged = false;
  wasCanceled = false;
  containerRef: ComponentRef<StageFluidMaterialComponent>;
  sap = new ISAPMaterialModel();
  supplementalMaterial: any;
  hasChanges = false;
  changedFluidPreviousForm: any;
  changedFluid = false;
  supplMaterial: SupplementalMaterial = null;
  foundMaterial = false;
  pumpFromEdit = false;
  rowIndex = null;
  sapChanged = false;
  canceledForSameLiquids = false;
  foundChanges = false;
  isPumpScheduleUpdated = false;
  fluidsAvailable: FluidModel[] = [];
  selectedFluidName: string = null;
  fluidColumns: ColumnDefinition[] = [
    new ColumnDefinition('displayName', 'Fluid'),
    new ColumnDefinition('slurryType', 'Slurry Type'),
  ];

  public errorMessages = {
    pumpScheduleFluidType: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, 'Type is required field.')
    ],
    pumpScheduleLinkedFluid: [
      new ErrorMessageModel(ERROR_TYPE.INVALID_VALUE, 'Invalid Fluid')
    ]
  };

  public readonly isFluidEditDisabled$: Observable<boolean> =
    this._applicationStateService.notifyIFactDown$
      .pipe(
        map(isDown => {
          return isDown || !this.isJobEditable;
        }),
        shareReplay()
      );

  constructor(
    private readonly _applicationStateService: ApplicationStateService,
    private readonly _changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private actualQuantityConfirmationService: ActualQuantityConfirmationService,
    private unitConversionService: UnitConversionService,
    public dynamicComponentService: DynamicComponentService,
    private readonly _scheduleStateFactory: PumpScheduleStateFactory,
    private controlPointService: ControlPointService2,
    public controlPointAdapter: ControlPointAdapter,
    public eventHub: EventHubService,
    private vcRef: ViewContainerRef,
    private psStageService: PumpScheduleStageService
  ) { }

  get identifier(): string {
    let id = this.stageInfoForm.value.slurry?.id ? this.stageInfoForm.value.slurry.id : this.stageInfoForm.value.slurry?.tempId ? this.stageInfoForm.value.slurry.tempId : this.stageInfoForm.value.slurry?.mudTempId;

    if (!id) {
      id = Math.random().toString(10).substring(2, 15);
      const mudFluid = this.psStageService.generateFluid(0, 'Mud', 'Manual', 'Planned', false, '');
      mudFluid.mudTempId = id;
      this.setSlurryValue(mudFluid)
    }
    
    return id;
  }

  public get _viewState(): ViewState {

    return this.stageState.viewState;
  }

  public get stageInfoForm(): UntypedFormGroup {
    if (this.jobType == 'ceServices') {
      this.stageState.form.get('actualDensity').setErrors(null);
      this.stageState.form.get('actualDensity').setValidators(null);
    }
    return this.stageState.form;
  }

  public get fluidName() {
    let data = this.stageState.form.get('fluidName').value;
    return data;
  }

  public get isScheduleEditView(): boolean {

    return this._viewState.isScheduleEditView;
  }

  public get isJobEditable(): boolean {

    return this._viewState.isJobEditable;
  }

  public isVisible$(field: string): Observable<boolean> {

    return this._viewState.isFieldVisible$(
      field,
      StageInfoComponent._visibleFields
    );
  }

  public get isMud$(): Observable<boolean> {

    return this.stageState.isMud$;
  }

  public get isNotPlug$(): Observable<boolean> {

    return this.stageState.isPlug$
      .pipe(
        map(isPlug => {
          return !isPlug;
        })
      );
  }

  public get isFluid$(): Observable<boolean> {

    return this.stageState.isFluid$;
  }

  public get isIFactsFluid$(): Observable<boolean> {

    return this.stageState.isIFactsFluid$;
  }

  public get isCementOrSpacer$(): Observable<boolean> {
    return this.typeName$.pipe(map(name => name == 'Cement' || 
      name == 'Spacer/Flush' ||
      name == 'Tuned® Spacer III' ||
      name == 'Tuned® Defense™ Cement Spacer' ||
      name == 'Tuned® Prime™ Cement Spacer'));
  }

  public get typeName$(): Observable<string> {

    return this.stageState.type$
      .pipe(
        map(t => t ? t.name : null)
      );
  }

  public get mudParameterDisplay$(): Observable<string> {
    return this.stageState.mudParameterDisplay$;
  }

  public get filteredFluids$(): Observable<FluidModel[]> {

    return this._filteredFluids$;
  }

  public get dropdownFluidItems$(): Observable<SelectItem[]> {

    return this.stageState.dropdownFluidItems$;
  }

  public get isFirstStageRemovable(): Observable<boolean> {
    return this._scheduleStateFactory.isFirstStageRemovableObv;
  }

  public get isStageRemovable(): boolean {
    return this.stageState.order !== 0;
  }

  public get isStageInsertable(): boolean {

    return this.isStageRemovable;
  }

  public get slurryId$(): Observable<string> {

    return this.stageState.selectedFluid$
      .pipe(
        map(fluid => {

          return fluid ? fluid.slurryId : null;
        })
      );
  }

  public get slurryName$(): Observable<string> {

    return this.stageState.selectedFluid$
      .pipe(
        map(fluid => {

          return fluid ? fluid.displayName : null;
        })
      );
  }

  public get avgRate$(): Observable<number> {

    return this.stageState.avgRate$;
  }

  public get plannedVolume$(): Observable<number> {

    return this.stageState.plannedVolume$
      .pipe(
        map(v => {

          return v || null;
        })
      );
  }

  public get topOfFluid$(): Observable<number> {

    return this.stageState.topOfFluid$
      .pipe(
        map(v => {

          return v || 0;
        })
      );
  }

  public get plannedScope3Co2e$(): Observable<number> {

    return this.stageState.plannedScope3Co2e$
      .pipe(
        map(v => {

          return v || 0;
        })
      );
  }

  public get actualScope3Co2e$(): Observable<number> {
    return this.stageState.actualScope3Co2e$
      .pipe(
        map(v => {

          return v || 0;
        })
      );
  }

  public get plannedScopeWithBlendCo2e$(): Observable<number> {
    return combineLatest(this.plannedScope3Co2e$, this.stageState.totalBlendCO2e$).pipe(map(([value1, value2]) => {
      return (isNaN(value1) ? 0 : value1) + (isNaN(value2) ? 0 : value2);  
    }));
  }

  public get actualScopeWithBlendCo2e$(): Observable<number> {
    return combineLatest(this.actualScope3Co2e$, this.stageState.totalBlendActualCO2e$).pipe(map(([value1, value2]) => { 
      return (isNaN(value1) ? 0 : value1) + (isNaN(value2) ? 0 : value2); 
    }));
  }

  public showFluidOnCP$(name: string) {
    return this.isFluid$.pipe(switchMap(p => p ? this.isVisible$(name) : of(false)));
  }

  public get loadOutQuantity$(): Observable<number> {

    return this.stageState.loadoutVolume$;
  }

  public get actualVolumePumped$(): Observable<number> {

    return this.stageState.actualVolume$;
  }

  public get dryWeight$(): Observable<number> {

    return this.stageState.dryWeight$;
  }

  public get dryVolume$(): Observable<number> {

    return this.stageState.dryVolume$;
  }

  public get plannedDensity$(): Observable<number> {

    return this.stageState.plannedDensity$;
  }

  public get isOffshoreJob$(): Observable<boolean> {

    return this._viewState.isOffshoreJob$;
  }

  public get isFluidSearchable(): boolean {

    return this._viewState.isScheduleEditView;
  }

  public get isFluidSelectable(): boolean {

    return !this._viewState.isScheduleEditView && !this._viewState.isCP4View;
  }

  public get isActualVolumeEditable(): boolean {

    return this._viewState.isCP4View;
  }

  public get isActualDensityEditable$(): Observable<boolean> {

    return combineLatest([
      this.stageState.isDrillingMud$,
      this.stageState.isPlug$
    ])
      .pipe(
        map(([isDrillingMud, isPlug]) => {

          return !isDrillingMud && !isPlug && this._viewState.isCP4View;
        })
      );
  }

  public getAllStages(): PumpScheduleStageModel[] {
    const psList = this._scheduleStateFactory.getListModel();
    const schedule = this._scheduleStateFactory.getModel();
    if (schedule) {
      return psList[schedule.order]?.stages;
    }
  }

  getOriginalPumpSchedule() {
    this.originalPumpSchedule = cloneDeep(this.getAllStages());
    if (!isNil(this.originalPumpSchedule) && this.index !== -1 && this.index <= this.originalPumpSchedule.length - 1) {
      //filter original pump with materials that blend and mix method is null or not null but with options yo change to null mix method 
      this.originalPumpSchedule[this.index].fluidMaterials = this.originalPumpSchedule[this.index]?.fluidMaterials?.filter((y: any) => {
        if (y?.loadoutVolumeUnit !== UnitType.SmallVolume && y?.materialType !== 'Mix Water'
          && (y?.overrideMixingProcedure?.value === 'PB' || y?.mixingProcedureId === null || y?.overrideMixingProcedureId === null)) {
          return y;
        }
      });
    }

    //after the pump schedule is loaded (first entry) and the user makes changes to either Mix Water or On the Fly - update the original PS
    (this.stageInfoForm.controls['fluidForm'] as FormGroup).controls['materials'].valueChanges
      .subscribe((x: any[]) => {
        if (!this.isPumpScheduleUpdated) {
          x.forEach((material, index) => {
            if (material.overrideMixingProcedureId === MixProcedures.PM || material.overrideMixingProcedureId === MixProcedures.PH) {
              this.originalForm.fluidForm.materials[index] = material;
            }
          });
        }
      });
  }

  getStageFormValues() {
    if (!this.stageLoadoutVolumeChanged) {
      this.originalForm = this.stageInfoForm.value;
      this.changedFluidPreviousForm = this.stageInfoForm.value;
      this.stageLoadoutVolumeChanged = true;
    }
  }

  getSupplementalMaterial() {
    return this.changedPumpSchedule[this.index].fluidMaterials.find(x => {
      const suppMat = new SupplementalMaterial(x.ifactMaterialName, x.materialType, x.concentration, x.concentrationUnit, x.sapMaterialNumber);
      return isEqual(suppMat, this.supplMaterial) && (x?.overrideMixingProcedure?.value === 'PB' || x?.mixingProcedureId === null || x?.overrideMixingProcedureId === null)
    });
  }

  getPushedBulkPlantsData(): PushedBulkPlantInfo[] {
    return this.stageState.getPushedBulkPlantsData();
  }

  prepareAndCheckPumpScheduleChanges() {
    this.getOriginalPumpSchedule();

    this.dryWeight$.subscribe(_ => {
      this.getStageFormValues();

      //wait 1.2 sec before showing to a user the changes popup
      of(null).pipe(delay(1200)).subscribe(_ => {
        this.supplMaterial = null;
        this.supplementalMaterial = null;
        
        //main method to determine changes
        if (!this.foundChanges) {
          this.checkStageChanges();
        }
      });
    });
  }

  ngAfterViewInit() {
    //pump schedule edit tab
    if (this.isPumpTab) {
      this.prepareAndCheckPumpScheduleChanges();
    }

    //wait untill fluids are loaded
    this.stageState.fluids$.subscribe(x => {
      if (x.length) {
        this.prepareAndCheckPumpScheduleChanges();
      }
    });
  }

  public checkStageChanges() {
    this.changedPumpSchedule = cloneDeep(this.getAllStages());
    //filter materials that blend, mix method is null or not null but with options to change to null mix method
    if (!isNil(this.index) && this.index !== -1 && !isNil(this.changedPumpSchedule)) {
      this.changedPumpSchedule[this.index].fluidMaterials = this.changedPumpSchedule[this.index].fluidMaterials?.filter((y: any) => {
        if (y?.loadoutVolumeUnit !== UnitType.SmallVolume && y?.materialType !== 'Mix Water'
          && (y?.overrideMixingProcedure?.value === 'PB' || y?.mixingProcedureId === null || y?.overrideMixingProcedureId === null)) {
          return y;
        }
      });
      
      if (this.wasCanceled || this.canceledForSameLiquids) {
        this.originalPumpSchedule = cloneDeep(this.changedPumpSchedule);
      }

      //handle when delete supplemental material on fluids tab
      let hasStageChanges = false;
      if (this.supplMaterial !== null) {
        this.supplementalMaterial = this.getSupplementalMaterial();
        hasStageChanges = this.supplementalMaterial !== undefined;
        let removingSupplementalMaterial = null;

        for (let index = 0; index < this.changedPumpSchedule.length; index++) {
          if (this.changedPumpSchedule[index].actualSlurry !== null) {
            removingSupplementalMaterial = this.changedPumpSchedule[index].fluidMaterials.find(x => {
              const suppMat = new SupplementalMaterial(x.ifactMaterialName, x.materialType, x.concentration, x.concentrationUnit, x.sapMaterialNumber);
              return isEqual(suppMat, this.supplMaterial) && (x?.overrideMixingProcedure?.value === 'PB' || x?.mixingProcedureId === null || x?.overrideMixingProcedureId === null)
            });
          }

          if (removingSupplementalMaterial) {
            break;
          }
        }

        //handle if we delete supplemental material but not blend and with not null mix method
        if (!removingSupplementalMaterial) {
          this.eventHub.onRemoveSupplementalModalSuccess$.next({ notFound: true, rowIndex: this.rowIndex });
        }
      }
      //handle changes in CP2/PS tab
      else {
        if (!isNil(this.originalPumpSchedule)) {
          //handle SAP if we have 2 or more the same liquid on stages to show only 1 popup
          let index = null;
          if (!isNil(this.sapMaterial) && this.sapChanged) {
            index = findIndex(this.originalForm.fluidForm.materials, (y: any) => y.id === this.sapMaterial.id, 0);
          }

          hasStageChanges = (index !== null ? index !== -1 : true) && !isEqual(this.changedPumpSchedule[this.index].fluidMaterials, this.originalPumpSchedule[this.index].fluidMaterials);
        }
      }

      //check if stage has a specific push to the bulk board
      const pushedBulkPlantsData = this.getPushedBulkPlantsData();
      const selectedStage = pushedBulkPlantsData?.find(y => y.pumpScheduleStageId === this.changedPumpSchedule[this.index].id);
      const hasAnyPush = isNil(selectedStage) ? false : selectedStage.isPushed;

      if (hasAnyPush) {
        this.loadingStatus = selectedStage.loadStatus;
      }

      //check if we have changes
      if (hasStageChanges && hasAnyPush && !this.shownModal) {
        this.hasChanges = true;
        this.shownModal = true;
        this.foundChanges = true;

        this.eventHub.onFoundChanges$.next();
        this._changeDetector.markForCheck();
      }

      if (this.wasCanceled) {
        this.wasCanceled = false;
      }

      this.canceledForSameLiquids = false;
    }
  }

  onConfirm() {
    //handle when confirmed delete supplemental material form fluids tab
    if (!isNil(this.supplementalMaterial)) {
      this.eventHub.onRemoveSupplementalModalSuccess$.next({ found: true, rowIndex: this.rowIndex });
      this.supplementalMaterial = null;
      this.supplMaterial = null;
    }
    //handle when confirmed materials form in CP2/PS tab
    else {
      if (this.changedFluid) {
        this.changedFluidPreviousForm = this.stageInfoForm.value;
      }
      this.changedFluid = false
      this.originalPumpSchedule = cloneDeep(this.changedPumpSchedule);
    }

    this.setDefaultValuesForChangesPopup();
    this.stageLoadoutVolumeChanged = false;
    this._changeDetector.detectChanges();
  }

  onCancel() {
    this.hasChanges = false;
    this.isPumpScheduleUpdated = true;
    this._changeDetector.detectChanges();

    //handle when canceled supplemental material form on fluids tab
    if (!isNil(this.supplementalMaterial)) {
      this.eventHub.onRemoveSupplementalModalSuccess$.next({ found: false });
    }
    //handle when canceled materials form CP2/PS tab
    else {
      this.wasCanceled = true;
      let hasVolumeChanges = false;
      let hasMixChanges = false;

      const originalFormMaterials = this.originalForm.fluidForm.materials;
      const stageFluidForm = this.stageInfoForm.controls.fluidForm;

      //check if we change any override volume for a material
      for (let index = 0; index < originalFormMaterials.length; index++) {
        if (originalFormMaterials[index]?.overrideVolume !== stageFluidForm.value.materials[index]?.overrideVolume) {
          hasVolumeChanges = true;
          break;
        }
      }

      //check if stage's load volume has changed (change either dead volume, loadout volume or bulk cement)
      const isStageLoadVolumeChanged = this.originalForm.fluidForm.loadoutVolume !== stageFluidForm.value.loadoutVolume;

      //handle mix method changes
      if (!isNil(this.material) && !hasVolumeChanges && !isStageLoadVolumeChanged && !this.sapChanged) {
        const index = findIndex(originalFormMaterials, (y: any) => y.id === this.material.id, 0);

        if (index !== -1) {
          originalFormMaterials[index].overrideMixingProcedureId = this.material.overrideMixingProcedureId;

          for (let index = 0; index < originalFormMaterials.length; index++) {
            if (originalFormMaterials[index].overrideMixingProcedureId !== stageFluidForm.value.materials[index].overrideMixingProcedureId
              && originalFormMaterials[index].id === stageFluidForm.value.materials[index].id
            ) {
              hasMixChanges = true;
              break;
            }
          }
          this.eventHub.onMixUpdate$.next(this.material);
          this.sapMaterial = null;
        }
      }

      //handle SAP changes
      if (!isNil(this.sapMaterial) && !hasVolumeChanges && !hasMixChanges && !isStageLoadVolumeChanged) {
        this.containerRef = this.vcRef.createComponent(StageFluidMaterialComponent);
        this.sap.materialName = this.sapMaterial.sapMaterialName;
        this.sap.materialNumber = this.sapMaterial.sapMaterialNumber
        this.containerRef.instance.onUpdateSapMaterial(this.sapMaterial, this.sap, this.stageState.fluidState, true, this.stageState);

        const index = findIndex(originalFormMaterials, (y: any) => y.id === this.sapMaterial.id, 0);
        if (index !== -1) {
          originalFormMaterials[index].sapMaterialName = this.sapMaterial.sapMaterialName;
          originalFormMaterials[index].sapMaterialNumber = this.sapMaterial.sapMaterialNumber;
          stageFluidForm.patchValue(this.originalForm.fluidForm, { emitEvent: false });  
        }
      }
      else {
        //setting previous values when canceled, no iFacts request changes
        if (!this.changedFluid) {
          const originalFluidForm = this.originalForm.fluidForm;
          if (originalFluidForm.deadVolume !== this.originalPumpSchedule[this.index].deadVolume) {
            originalFluidForm.deadVolume = this.originalPumpSchedule[this.index].deadVolume;
          }

          stageFluidForm.patchValue(originalFluidForm);
          this.stageState.patchEvents(this.originalForm.events);
          stageFluidForm.updateValueAndValidity();
          this.stageLoadoutVolumeChanged = false;

          //handle when we have 2 or more same liquids in 1 pump schedule 
          //checking if we have the same liquid in a pump schedule
          const stagesWithSlurry = this.originalPumpSchedule.filter(x => !isNil(x.actualSlurry));
          const uniqueStages = uniq(stagesWithSlurry.map(x => x.actualSlurry.id));
          const hasSameSlurry = stagesWithSlurry.length !== uniqueStages.length;
          if (hasSameSlurry) {
            this.eventHub.onCanceledSameLiquids$.next();
          }
        }
        //handle when canceled iFacts request changes
        else {
          const slurryId = isNil(this.changedFluidPreviousForm.actualSlurry) ? this.changedFluidPreviousForm.slurry.id : this.changedFluidPreviousForm.actualSlurry.id; 
          this.stageInfoForm.controls.selectedFluidId.setValue(slurryId);
          stageFluidForm.patchValue(this.changedFluidPreviousForm.fluidForm, { emitEvent: false });
          stageFluidForm.updateValueAndValidity();
          this.changedFluid = false;
        }
      }
      this._changeDetector.markForCheck();
    }

    this.setDefaultValuesForChangesPopup();
    this._changeDetector.detectChanges();
  }

  public get isMudEditable(): boolean {

    return this._viewState.isScheduleEditView || this._viewState.isCP1View;
  }

  public get isShowFluidLink(): boolean {
    return !this._viewState.isCP4View
  }

  public get isFluidLinkDisable$(): Observable<boolean> {
    return combineLatest([
      this.controlPointService.controlPoint1State$,
      this.controlPointService.controlPoint2State$
    ]).pipe(
      map(([cp1State, cp2State]) => {
        if (this.isNewJob)
          return false;

        const isCP1SubmittedOrApproved = cp1State === ControlPointState.Submitted
          || cp1State === ControlPointState.Approved;

        const isCP2PreparedOrCompleted = cp2State === ControlPointState.Prepared
          || cp2State === ControlPointState.Completed;

        const isCP1InProgress = cp1State == ControlPointState.NonSubmitted;
        const isCP2InProgress = cp2State == ControlPointState.NonSubmitted;

        if (this._viewState.isCP1View) {
          return isCP1SubmittedOrApproved && !isCP2InProgress;
        }

        if (this._viewState.isCP2View) {
          return isCP2PreparedOrCompleted && !isCP1InProgress;
        }

        if (this.isScheduleEditView) {
          return isCP1SubmittedOrApproved && isCP2PreparedOrCompleted;
        }

        return false;
      })
    );
  }

  public get isNewJob(): boolean {
    return this.job == null || this.job.id == ''
      || this.job.id == '00000000-0000-0000-0000-000000000000';
  }

  public get isFluidChangeDisabled$(): Observable<boolean> {

    return this._viewState.isCP1Submitted$
      .pipe(
        map(isCp1Submitted => {

          return isCp1Submitted;
        })
      );
  }

  public get stageTypeItems$(): Observable<SelectItem<string>[]> {
    const dropdownStageTypeItems$ = this.stageState.dropdownStageTypeItems$;

    return combineLatest([dropdownStageTypeItems$, this.isPlugJob$]).pipe(
      map(([items, isPlugJob]) => { 
        items.forEach((item: any) => {
          if (item.label === FLUID_TYPE_SCHEDULE.PLUG_DART && isPlugJob!=null) {
            item.disabled = !isPlugJob;
          }
        });
        return items;
      })
    );
  }

  public get cogsAvailable$(): Observable<boolean> {
    return this.stageState.cogs$
      .pipe(
        map(stageCost => {
          return stageCost.totalCOGS !== null
            // ..and no empty children COGS
            && !stageCost.fluidMaterials.some(x => x.totalCOGS == null);
        })
      );
  }

  public get cogs$(): Observable<number> {

    return this.stageState.cogs$
      .pipe(
        map(stageCost => {
          return stageCost.totalCOGS;
        })
      );
  }

  public get anyMaterialMissingBulkDensity$(): Observable<boolean> {

    return this.stageState.anyMaterialMissingBulkDensity$;
  }

  ngOnInit() {
    this.isPlugJobSubject.next(this.job?.withPlug);
    this._subscriptions.add(
      this._applicationStateService.jobTypeChanged.subscribe((value: any) => {
        this.isPlugJobSubject.next(value.context.withPlug);
      })
    );
    this.eventHub.onRemoveSupplemental$.subscribe(x => {
      if (x.name !== "" && x.name !== null) {
        this.supplMaterial = new SupplementalMaterial(x.name, x.type, x.concentration, x.concentrationUnit, x.sapMaterialNumber);
        this.rowIndex = x.rowIndex;
        this.checkStageChanges();
      }
      else {
        this.eventHub.onRemoveSupplementalModalSuccess$.next({ notFound: true, rowIndex: x.rowIndex });
      }
    });

    this.eventHub.onChangedSAP$.subscribe(x => {
      this.sapChanged = true;
      //wait 1.2 sec before showing to a user the changes popup (time sync with other places)
      this.stageState.fluidState.model$.pipe(delay(1200)).subscribe(y => {
       //check if a fluid is loaded
        if (y.fluidMaterial.length) {
          this.sapMaterial = cloneDeep(x);
          this.stageLoadoutVolumeChanged = false;
          this.getStageFormValues();
          this.checkStageChanges();
        }
      });
    });

    this.eventHub.onMixChanged$.subscribe(x => {
      this.sapChanged = false;
      this.sapMaterial = null;
      this.material = cloneDeep(x);
      this._changeDetector.markForCheck();
    });

    this.eventHub.onCanceledSameLiquids$.subscribe(_ => {
      this.canceledForSameLiquids = true;
      this._changeDetector.markForCheck();
    });

    this.eventHub.onFoundChanges$.subscribe(_ => this.foundChanges = true);

    this.weight = this.unitConversionService.getCurrentUnitMeasure(UnitType.Weight);
    this.volume = this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume);
    this._filteredFluids$ =
      combineLatest([
        this.stageState.availableFluids$,
        this._fluidSearchCompleteSrc.asObservable()
      ])
        .pipe(
          map(([fluids, searchKey]) => {
            return this._filterFluids(fluids, searchKey);
          }),
          shareReplay()
        );

    this._filteredFluids$.subscribe(fluids => {
      this.fluidsAvailable = [...fluids];
    });
    
    this._scheduleStateFactory.updateCurrentStageRemovable();

    this._subscriptions.add(
      this.stageInfoForm.get('slurry').valueChanges.subscribe(fluid => {
        this.stageState.setLinkedFluidActual(this.stageState.isIFactsFluid(fluid) ? fluid : null);
      })
    );

    this._subscriptions.add(
      combineLatest(
      [
        this.stageInfoForm.get('selectedFluidId').valueChanges,
        this.stageState.fluids$
      ]
      ).subscribe(([fluidId, fluids]: [string, FluidModel[]]) => {
        const fluid = fluids.find(f => f.id === fluidId);
        this.stageState.setLinkedFluidActual(this.stageState.isIFactsFluid(fluid) ? fluid : null);
        this.selectedFluidName = fluid.displayName;
        this.changedFluid = true;
        this.stageLoadoutVolumeChanged = true; 
        this._changeDetector.markForCheck();
      })
    );
    if (this.controlPointType == 2) {
      var componentRef = new ThickeningTimeTestResultComponent();
      this._subscriptions.add(
        this.stageState.testTables$.subscribe((tables) => {  
          componentRef.testTables = tables;
          componentRef.availableSlurries = this.stageState._model?.slurry?.requestInfo?.availableSlurries;
          componentRef.stageState = this.stageState;
          componentRef.canEdit = false;
        }));

      this._subscriptions.add(
        this.stageState.selectedFluid$.subscribe((tables) => {
          componentRef.updateThickeningTime();
        }));
    }

    this.selectedFluidName = this.stageInfoForm.value?.slurry?.displayName;
  }

  public ngOnDestroy() {

    if (this.loadingSubscription) {

      this.loadingSubscription.unsubscribe();
    }

    this._subscriptions.unsubscribe();

    this._fluidSearchCompleteSrc.complete();

    this.dynamicComponentService.destroyComponent();
  }

  onFocusOut(input: any): void {

    let plannedVolume = this.stageState.form.get('plannedVolume')?.value;

    if (!plannedVolume)
      return;

    const setQuantity = +input.value;
    plannedVolume = getDisplayValue(plannedVolume, this.unitConversionService.getApiUnitMeasure(UnitType.LargeVolume), this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume));

    if (setQuantity && setQuantity > plannedVolume * 1.25) {

      this.dialogService.open(ActualQuantityConfirmationComponent, {
        header: 'Confirmation',
        width: '500px',
        autoZIndex: true,
        data: {
          plannedQty: Math.round((plannedVolume + Number.EPSILON) * 100) / 100,
          actualQty: Math.round((setQuantity + Number.EPSILON) * 100) / 100
        }
      });

      this.actualQuantityConfirmationService.actualQuantityChange$
        .pipe(first())
        .subscribe(isConfirmed => {

          if (!isConfirmed) {
            this.stageState.form.get('actualVolumePumped').setValue('');
          }
        });
    }
  }

  public changeFluidName(event): void {
    this.stageState.fluidName$.next({
      id: this.stageInfoForm.value.fluidForm.id === null ? this.stageInfoForm.value.slurry.tempId : this.stageInfoForm.value.fluidForm.id,
      name: event.target.value
    });
  }

  public showCogsHelp(): void {

    this.stageState.showCogsHelp();
  }

  public showMissingBulkDensityHelp(): void {

    this.stageState.showMissingBulkDensityHelp();
  }

  private _filterFluids(fluids: FluidModel[], searchKey: string): FluidModel[] {
    if (!searchKey) {
      return [...fluids];
    }

    return fluids.filter(fluid => {
      if (fluid.displayName)
        return fluid.displayName.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1;
    });
  }

  public openMudParameters(): void {
    this.dynamicComponentService.createComponent(
      MudParametersComponent,
      {
        form: this.stageInfoForm.controls.mudParameter,
        order: this.stageState.order,
        isPumpDefault: this.isPumpDefault,
        identifier: this.identifier,
        fluid: this.stageInfoForm.value.slurry
      });

  }

  public insertStageBefore(): void {

    this.stageState.insertStageBefore();
    this._scheduleStateFactory.updateCurrentStageRemovable();
  }

  public insertStageAfter(): void {

    this.stageState.insertStageAfter();
    this._scheduleStateFactory.updateCurrentStageRemovable();
  }

  public deleteStage(): void {

    this.stageState.deleteStage();
  }

  public get listPumpScheduleStageStateManager$(): Observable<PumpScheduleStageStateManager[]> {
    return this._scheduleStateFactory.listPumpScheduleStageStateManager$
  }

  public linkFluidsActual(): void {
    const fluidSelectSubscription = this.stageState.linkFluidsActual().subscribe(fluid$ => {

      this.loadingSubscription = fluid$.subscribe(fluid => {

        if (fluid) {
          this.stageState.setLinkedFluidActual(fluid);
        }

        // There might not be loading subscription returned if linking already linked fluid.
        if (this.loadingSubscription) {

          this.loadingSubscription.unsubscribe();
        }

        this.loadingSubscription = null;
      });

      // Angular does not see field value changes when field (loadingSubscription)
      // is set in other subscription callback
      this._changeDetector.markForCheck();

      fluidSelectSubscription.unsubscribe();
    });

    this._subscriptions.add(fluidSelectSubscription);
  }

  public linkFluids(): void {
    const fluidSelectSubscription = this.stageState.linkFluids().subscribe(fluid$ => {

      this.loadingSubscription = fluid$.subscribe(fluid => {
        if (fluid) {
          this.onStageAlign.emit();
          this.stageState.setLinkedFluid(fluid);
        }

        // There might not be loading subscription returned if linking already linked fluid.
        if (this.loadingSubscription) {

          this.loadingSubscription.unsubscribe();
        }

        this.loadingSubscription = null;
      });

      // Angular does not see field value changes when field (loadingSubscription)
      // is set in other subscription callback
      this._changeDetector.markForCheck();

      fluidSelectSubscription.unsubscribe();
    });

    this._subscriptions.add(fluidSelectSubscription);
  }

  public validateFluidValue() {
    const control = this.stageInfoForm.get('slurry') as UntypedFormControl;

    if (control) {

      if (control.value && isPrimitive(control.value)) {
        control.setErrors({ 'ivalidValue': true });
      }
      else {
        control.setErrors(null);
      }
    }
  }

  transformResult(value) {
    return this.unitConversionService.ConvertEmissionsToUserUnit(value, this.weight, this.volume);
  }

  private setDefaultValuesForChangesPopup() {
    this.hasChanges = false;
    this.shownModal = false;
    this.sapChanged = false;
    this.foundChanges = false;
  }

  onFluidLazyLoad(keyword: string) {
    this._fluidSearchCompleteSrc.next(keyword);
  }

  onFluidChange(option: FluidModel) {
    this.selectedFluidName = option.displayName;
    this.setSlurryValue(option);
  }

  onFluidClear() {
    this.selectedFluidName = null;
    this.setSlurryValue(null);
    this._fluidSearchCompleteSrc.next('');
  }

  private setSlurryValue(fluid: FluidModel) {
    const control = this.stageInfoForm.get('slurry') as UntypedFormControl;
    control.setValue(fluid);
  }
  
} 
