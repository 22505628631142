import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallSheet, JobProgram, LocalWellReviewModel, PostJobReport, RiskAssessment } from 'apps/vida/src/modules/control-point/models';
import { HcUrlPattern, VidaFileType } from 'apps/vida/src/modules/shared/constant';
import { IOperationResult } from 'apps/vida/src/modules/shared/constant/operation-result';
import { IIcemFileImportModel, IIcemFileModel, IIcemParsedModel } from 'apps/vida/src/modules/shared/models';
import { environment } from 'libs/environment';
import { Attachment, ReportPayload } from 'libs/models';
import { interval, Observable, Subject, Subscriber, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, take, tap, catchError } from 'rxjs/operators';
import { SessionService } from './session.service';
import { IdGenerator } from 'libs/helpers';
import { IIcemFileCloneModel } from 'apps/vida/src/modules/shared/models/icem-file-clone.model';
import { ISetPrimaryFlagModel } from 'apps/vida/src/modules/shared/models/set-primary-flag';
import { first } from 'lodash';
import { Exception } from '@microsoft/applicationinsights-web';
import { MaterialLoadSheet } from 'apps/vida/src/modules/control-point/models/material-load-sheet.model';
import { CopyJobDocumentsModel } from 'apps/vida/src/modules/edit-job/models/copy-job-documents.model';
import { MlsSource } from 'libs/constants/mls-source';

@Injectable()
export class HttpCommanderService {

  fileUploader: any;
  percent: number;
  status$: Subject<any>;
  hdfDocWs: any;
  _downloadTasks$: { [token: string]: { subject: Subject<TaskDownloadStatus>, downloadType: string }} = {};

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  startUpload(jobId, file, destination, isOverwrite, fileDescription, vidaFileType: VidaFileType, jobProgramFileName) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    const webSocketHost = this.getWebSocketHost(environment.httpCommanderUrl);

    this.status$ = new Subject();

    this.fileUploader = new StreamingFileUploader(jobId, file, this.http, isOverwrite, fileDescription, vidaFileType, jobProgramFileName);
    this.fileUploader.chunkSize = 1 * 1024 * 1024;
    this.fileUploader.numberOfWorkers = 1;
    this.fileUploader.websocketEndpoint = `${webSocketHost}/api/streamingupload/openconnection?token=${hcToken}`;
    this.fileUploader.reassembleFileEndpoint = (`${environment.httpCommanderUrl}/api/StreamingUpload/Reassemble?token=${hcToken}`).replace('http://', 'https://');

    this.fileUploader.onmessage = (msg) => {
      this.status$.next(msg);
    };
    this.fileUploader.upload(destination);
  }

  startUploadMultiple(jobId, file, destination, isOverwrite, fileDescription, vidaFileType: VidaFileType, jobProgramFileName, onMessageCb) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    const webSocketHost = this.getWebSocketHost(environment.httpCommanderUrl);

    const fileUploader = new StreamingFileUploader(jobId, file, this.http, isOverwrite, fileDescription, vidaFileType, jobProgramFileName);
    fileUploader.chunkSize = 1 * 1024 * 1024;
    fileUploader.numberOfWorkers = 1;
    fileUploader.websocketEndpoint = `${webSocketHost}/api/streamingupload/openconnection?token=${hcToken}`;
    fileUploader.reassembleFileEndpoint = (`${environment.httpCommanderUrl}/api/StreamingUpload/Reassemble?token=${hcToken}`).replace('http://', 'https://');

    fileUploader.onmessage = (msg) => {
      if (typeof onMessageCb === 'function') onMessageCb(msg);
    };
    fileUploader.upload(destination);

    return fileUploader;
  }

  cancel() {
    if (this.fileUploader) {
      this.fileUploader.stop();
    }
  }
  getIcemFiles(jobId: string, wellId: string, isIcemRealtime: boolean, allFilesInFolder: boolean = false): Observable<any> {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.get<IIcemFileModel[]>(`${environment.httpCommanderUrl}/api/ICemParser/GetICemFileList?token=${hcToken}&jobId=${jobId}&wellId=${wellId}&isIcemRealtime=${isIcemRealtime}&allFilesInFolder=${allFilesInFolder}`)
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'filePath');
        })
      );
  }

  preProcessICemFile(cementingJob: any) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/PreprocessICemFile?token=${hcToken}`, cementingJob);
  }

  parseIcemFile(icemPayload: IIcemFileImportModel) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/ParseICemFile?token=${hcToken}`, icemPayload);
  }

  processIcemFile(icemPayload: IIcemFileImportModel, isIcemRealtime: boolean) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/ProcessICemFile?token=${hcToken}&isIcemRealtime=${isIcemRealtime}`, icemPayload);
  }

  updateIcemDescription(icemPayload: IIcemFileImportModel) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/UpdateIcemDescription?token=${hcToken}`, icemPayload);
  }

  cloneIcemFiles(icemClonePayload: IIcemFileCloneModel) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/CloneICemListFile?token=${hcToken}`, icemClonePayload);
  }

  setPrimaryFlag(icemClonePayload: ISetPrimaryFlagModel) {
    const hcToken = encodeURIComponent(this.sessionService.user?.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/SetPrimaryFlag?token=${hcToken}`, icemClonePayload);
  }

  moveicemFileToJob(iCemParsedInfo: IIcemParsedModel, isIcemRealtime: boolean) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/MoveImagesOfIcemFile?token=${hcToken}&isIcemRealtime=${isIcemRealtime}`, iCemParsedInfo);
  }

  deleteTempFolderContainICemFile(icemFileImportModel: IIcemFileImportModel) {
    const hcToken = encodeURIComponent(this.sessionService.user.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/ICemParser/DeleteTempFolder?token=${hcToken}`, icemFileImportModel);
  }

  getWebSocketHost(httpHost: string) {
    return httpHost.replace('http', 'ws');
  }

  moveJobToOtherWell(jobId: string, sourceWellId: string, destWellId: string, inverse: boolean = false) {
    const obj: any = {
      jobId: jobId,
      sourceWellId: sourceWellId,
      destWellId: destWellId,
      inverse: inverse
    };
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/MoveJobToAnotherWell`, JSON.stringify(obj));
  }

  createJobOrWellFolderPath(wellId: string, jobId?: string, addRiskAssessment?: boolean) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/CreateJobOrWellFolderPath?wellId=${wellId}&jobId=${jobId}&addRiskAssessment=${addRiskAssessment}`);
  }

  createWellInfoFolderPath(wellId: string, wellNumber: string) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/CreateWellInfoFolderPath?wellId=${wellId}&wellNumber=${wellNumber}`);
  }

  getFilesByVirtualRelativeFolderPath(virtualRelativeFolderPath: string, vidaFileType: VidaFileType, checkForSeparateApprovalFile: boolean = false): Observable<any> {
    return this.Get(`${environment.httpCommanderUrl}/api/VidaFileManager/GetFilesByVirtualRelativeFolderPath?virtualRelativeFolderPath=${virtualRelativeFolderPath}&vidaFileType=${vidaFileType}`)
      .pipe(
        map((res: any) => {
          if (checkForSeparateApprovalFile) { this.mapPathForSeparateApprovalFile(res); }
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  getJobApprovalFilesByJob(wellId: string, jobId?: string) {
    return this.Get(`${environment.httpCommanderUrl}/api/VidaFileManager/GetJobApprovalFilesByJob?wellId=${wellId}&jobId=${jobId}`)
      .pipe(
        map((res: any) => {

          return mappingDownLoadPath(res, 'approvalFilePathDownload');
        })
      );
  }

  getApprovalFileInfoByVirtualRelativeFilePath(virtualRelativeFilePath: string) {
    const filePath = encodeURIComponent(virtualRelativeFilePath);
    return this.Get(`${environment.httpCommanderUrl}/api/VidaFileManager/getApprovalFileInfoByVirtualRelativeFilePath?virtualRelativeFilePath=${filePath}`)
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'approvalFilePathDownload');
        })
      );
  }

  saveJobProgram(jobPrograms: JobProgram[], jobId: string) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SaveJobProgram?jobProgramFiles${jobPrograms}&jobId=${jobId}`, JSON.stringify(jobPrograms))
      .pipe(
        map((res: any) => {
          this.mapPathForSeparateApprovalFile(res);
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  deletePhysicalFile(virtualRelativeFilePath: string, vidaFileType: VidaFileType = null) {
    const filePath = encodeURIComponent(virtualRelativeFilePath);
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/DeleteFile?virtualRelativeFilePath=${filePath}&fileType=${vidaFileType}`);
  }

  deletePhysicalFileByHcHandler(virtualFolderPath: string, fileName: string, vidaFileType: VidaFileType = null) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/DeleteFileFromHCHandler?virtualFolderPath=${virtualFolderPath}&fileName=${encodeURIComponent(fileName)}&fileType=${vidaFileType}`);
  }

  deleteApprovalFilesNotLinked(virtualRelativeApprovalFolderPath: string) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/DeleteApprovalFilesNotLinked`, JSON.stringify(virtualRelativeApprovalFolderPath));
  }

  saveLocalWellReview(localWellReview: LocalWellReviewModel) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SaveLocalWellReview`, JSON.stringify(localWellReview));
  }

  saveCallSheet(callSheets: CallSheet[], jobId: string) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SaveCallSheet?callSheetFiles=${callSheets}&jobId=${jobId}`, JSON.stringify(callSheets))
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  savePostJobReport(postJobReports: PostJobReport[], jobId: string) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SavePostJobReport?postJobReports=${postJobReports}&jobId=${jobId}`, JSON.stringify(postJobReports))
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  setPrimaryFile(virtualRelativeFilePath: string, jobId: string, wellId: string, fileType: VidaFileType) {
    let params = new HttpParams();
    if (virtualRelativeFilePath) {
      params = params.append("virtualRelativeFilePath", virtualRelativeFilePath);
    }

    params = params.append("jobId", jobId);
    params = params.append("wellId", wellId);
    params = params.append("fileType", String(fileType));

    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SetPrimaryFile`, null, params)
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  setIsPrimaryFlagDisabledInAllFiles(jobId: string, wellId: string, isDisabled: boolean = false) {
    const params = new HttpParams({ fromObject: { jobId, wellId, isDisabled: isDisabled.toString() }}).toString();
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SetIsPrimaryFlagDisabledInAllFiles?${params}`)
  }

  checkPrimaryJobLogFile(jobId: string, wellId: string) {
    return this.Get(`${environment.httpCommanderUrl}/api/VidaFileManager/CheckJobLogPrimaryFile?jobId=${jobId}&wellId=${wellId}`);
  }

  saveRiskAssessment(riskAssessment: RiskAssessment[], jobId: string) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SaveRiskAssessment?riskAssessmentFiles=${riskAssessment}&jobId=${jobId}`, JSON.stringify(riskAssessment))
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  saveMaterialLoadSheet(materialLoadSheets: MaterialLoadSheet[], jobId: string, source:MlsSource) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/SaveMaterialLoadSheet?materialLoadSheetFiles=${materialLoadSheets}&jobId=${jobId}&source=${source}`, JSON.stringify(materialLoadSheets))
      .pipe(
        map((res: any) => {
          return mappingDownLoadPath(res, 'filePathDownload');
        })
      );
  }

  generateReport(reportPayload: ReportPayload): Observable<IOperationResult> {
    const hcToken = encodeURIComponent(this.sessionService.user?.hcToken);
    return this.http.post<{success: boolean, data: string}>(`${environment.httpCommanderUrl}/api/report/startgenerate?token=${hcToken}`, reportPayload)
      .pipe(
        switchMap((res: {success: boolean, data: string}) => {
            if (res.success) {
              const reportId = res.data;
              const checkStatusReq$ = this.http.get<{success: boolean, data: string, message: string}>(`${environment.httpCommanderUrl}/api/report/checkstatus?token=${hcToken}&id=${reportId}`)
                          .pipe(
                            filter((res) => 
                              !res.success || (res.success && (res.data == 'Done' || res.data == 'Error' || res.data == 'Not Found')))
                          );
              return interval(1500).pipe(switchMap(() => checkStatusReq$), take(1));
            }
        })
      );
  }

  downloadHdfAttachments(jobId: string, wellId: string, fileList: string[]): Observable<Attachment[]> {
    const hcToken = encodeURIComponent(this.sessionService.user?.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/hdf/${wellId}/${jobId}/attachments?token=${hcToken}`, fileList);
  }

  copyJobDocuments(model: CopyJobDocumentsModel) {
    return this.Post(`${environment.httpCommanderUrl}/api/VidaFileManager/CopyJobDocuments`, JSON.stringify(model));
  }

  GenerateMaterialSummaryReport(payload: any) {
    const hcToken = encodeURIComponent(this.sessionService.user?.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/report/GenerateMaterialSummaryReport?token=${hcToken}`, payload);
  }

  generateJSQASummaryReport(payload: any) {
    const hcToken = encodeURIComponent(this.sessionService.user?.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/report/GenerateJSQASummaryReport?token=${hcToken}`, payload, {
      responseType: 'blob' as 'json'
    });
  }

  generateCo2eSummaryReport(payload: any) {
    const hcToken = encodeURIComponent(this.sessionService.user?.hcToken);
    return this.http.post<any>(`${environment.httpCommanderUrl}/api/report/GenerateCo2eSummaryReport?token=${hcToken}`, payload);
  }

  public Post<T>(apiUrl: string, object?: any, params: HttpParams = null): Observable<T> {
    const headers = new HttpHeaders({ 'Authorization': `${this.sessionService.user?.hcToken}`, 'Content-Type': 'application/json' });

    return this.http.post<T>(apiUrl, object, { headers: headers, params: params });
  }

  public Get<T>(apiUrl: string): Observable<T> {
    const headers = new HttpHeaders({ 'Authorization': `${this.sessionService.user?.hcToken}`, 'Content-Type': 'application/json' });

    return this.http.get<T>(apiUrl, { headers: headers });
  }

  private _downloadLinks = {
    'iFacts': {
      startDownload: `${environment.httpCommanderUrl}/api/ifacts/StartDownload`,
      checkStatus: `${environment.httpCommanderUrl}/api/ifacts/CheckDownloadStatus`
    },
    'hdf': {
      startDownload: `${environment.httpCommanderUrl}/api/hdf/StartDownload`,
      checkStatus: `${environment.httpCommanderUrl}/api/hdf/CheckDownloadStatus`
    }
  };
  downaloadJSQADoc(res,fileName) {
    const url = window.URL.createObjectURL(res);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.docx`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  downloadHdfReports(jobId: string, wellId: string
    , postData: any[]): Observable<TaskDownloadStatus> {
      return this.downloadJobAttachments(jobId, wellId
        , 'hdf'
        , postData);
  }

  downloadIFactsReports(jobId: string, wellId: string
    , postData: any[]): Observable<TaskDownloadStatus> {
      return this.downloadJobAttachments(jobId, wellId
        , 'iFacts'
        , postData);
  }

  downloadJobAttachments(jobId: string, wellId: string
    , downloadType: string
    , postData: any[]): Observable<TaskDownloadStatus>
  {
    if (jobId == null || wellId == null)
      throw 'argument jobId or wellId cannot be null';
    if (postData == null || postData.length == 0)
      throw 'argument postData should contain at least one item';
    
    const result$ = new Subject<TaskDownloadStatus>();
    const files: FileDownloadStatus[] = [];
    postData.forEach((item) => files.push({
      fileName: item.fileName, //remove this
      status: DownloadStatusEnum.STARTED
    }));
    
    this.Post<QResponse<string>>(this._downloadLinks[downloadType].startDownload, 
      JSON.stringify({
        wellId: wellId,
        jobId: jobId,
        attachments: postData
      }))
    .subscribe((response: QResponse<string>) => {
      const taskId: string = response.result;
      if (response.statusCode == 200 && taskId !== null) {
        this._downloadTasks$[taskId] = {
          subject: result$,
          downloadType: downloadType
        };
        result$.next(new TaskDownloadStatus(taskId, DownloadStatusEnum.STARTED, files, null));
        this.runCheckInterval(taskId);
      }
      else if (response.statusCode == 409 && taskId !== null) {
        result$.next(new TaskDownloadStatus(null, DownloadStatusEnum.ERROR, files, "Download already stated."));
        result$.complete();
      } else {
        result$.next(new TaskDownloadStatus(null, DownloadStatusEnum.ERROR, files, null));
        result$.complete();
      }
    });
    return result$;
  }

  private getDownloadSubject(taskId: string): Subject<TaskDownloadStatus>{
    if (this._downloadTasks$[taskId] === undefined)
      throw 'can\'t abort - no download is in progress';
    return this._downloadTasks$[taskId].subject;
  }

  private getDownloadType(taskId: string): string {
    if (this._downloadTasks$[taskId] === undefined)
      throw 'can\'t abort - no download is in progress';
    return this._downloadTasks$[taskId].downloadType;
  }
  
  private runCheckInterval(taskId: string): void {
    const taskFinished$ = this.getDownloadSubject(taskId).pipe(
      filter((status: TaskDownloadStatus) => status.isFinished)
    );
    interval(1000)
      .pipe(
        takeUntil(taskFinished$),
        tap(() => this.checkJobAttachmentsDownload(taskId).subscribe())
      ).subscribe();
  }

  abortJobAttachmentsDownload(taskId: string) {
    this.checkJobAttachmentsDownload(taskId, 'abort').subscribe();
  }

  //cmd: check or abort
  private checkJobAttachmentsDownload(taskId: string, cmd: string = 'check'): Observable<TaskDownloadStatus> {
    const sbj$ = this.getDownloadSubject(taskId);
    const downloadType = this.getDownloadType(taskId);

    return this.Get<QResponse<TaskDownloadStatus>>(`${this._downloadLinks[downloadType].checkStatus}?taskid=${taskId}&cmd=${cmd}`)
      .pipe(
        map((resposne: QResponse<TaskDownloadStatus>) => {
          const taskStatus: TaskDownloadStatus = new TaskDownloadStatus(
            resposne.result.taskId,
            resposne.result.status,
            resposne.result.files,
            resposne.result.message);
            
          sbj$.next(taskStatus);
          if (taskStatus.isFinished) {
            sbj$.complete();
            this._downloadTasks$[taskId] = undefined;
          }
          return taskStatus;
      }),
      catchError((err: any) => {
        const taskStatus: TaskDownloadStatus = new TaskDownloadStatus(
                                                  taskId,
                                                  DownloadStatusEnum.ERROR,
                                                  [],
                                                  err);
        sbj$.next(taskStatus);
        sbj$.complete();
        this._downloadTasks$[taskId] = undefined;
        return of(taskStatus);
      })
      );
  }

  private mapPathForSeparateApprovalFile(res: any) {
    if (res && res.statusCode === 200) {
      if (Array.isArray(res.result)) {
        res.result.forEach(e => {
          if (e.separateApprovalFile && e.separateApprovalFile.approvalFilePathDownload) {
            e.separateApprovalFile.approvalFilePathDownload = replaceHcUrl(e.separateApprovalFile.approvalFilePathDownload);
          }
        });
      }
      else {
        if (res.result.separateApprovalFile && res.result.separateApprovalFile.approvalFilePathDownload) {
          res.result.separateApprovalFile.approvalFilePathDownload = replaceHcUrl(res.result.separateApprovalFile.approvalFilePathDownload);
        }
      }
    }
  }

  checkForCustomRiskAssessmentFile(data: any): RiskAssessment {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result) {
        let riskAssessmentFiles: RiskAssessment[] = result.map(riskAss => {
          const temp = Object.assign(new RiskAssessment, riskAss);
          return temp;
        });
        const riskAssessment = riskAssessmentFiles.find((riskAss: any) => riskAss.isLastUsed && riskAss.description !== 'template.docx');
        
        if (riskAssessment) {
          let fileName = riskAssessment.getFileName();
          if (fileName.trim() !== "template.docx".trim()) {
            return riskAssessment;
          }
        }
      }
    }
  }
}

export function replaceHcUrl(path: string) {
  return path ? path.replace(HcUrlPattern, environment.httpCommanderUrl) : null;
}

export function mappingDownLoadPath(res: any, propertyFilePath: string) {
  if (res && res.statusCode === 200) {
    if (Array.isArray(res.result)) {
      res.result.forEach(e => {
        e[propertyFilePath] = replaceHcUrl(e[propertyFilePath]);
      });
    }
    else {
      res.result[propertyFilePath] = replaceHcUrl(res.result[propertyFilePath]);
    }
  }
  return res;
}

export function StreamingFileUploader(jobId, file, httpClient: HttpClient, isOverwrite: boolean, fileDescription, vidaFileType: VidaFileType, jobProgramFileName: string) {
  const that = this;
  const tempFileName = IdGenerator.getUnique();

  let finishedWorkers = 0;
  let workers = [];

  this.http = httpClient;
  this.chunkSize = 1 * 1024 * 1024;
  this.numberOfWorkers = 3;
  this.websocketEndpoint = '';
  this.reassembleFileEndpoint = '';

  this.upload = function (destination) {
    this.destination = destination;
    const fileSize = file.size;
    const fileName = file.name;
    const blockSize = Math.floor(fileSize / this.numberOfWorkers);
    workers = [];

    that.onmessage({
      cmd: 'Info',
      param: {
        state: 'preparing',
        message: 'Prepare to upload...'
      }
    });

    for (let i = 0; i < this.numberOfWorkers; i++) {
      const worker = new Worker('assets/js/vida-streaming-upload.worker.js');
      worker.onmessage = this.onWorkerMessage;
      workers.push(worker);
      worker.postMessage({
        cmd: 'Start',
        param: {
          totalBlock: this.numberOfWorkers,
          blockNumber: i,
          blockName: tempFileName,
          startOffset: i * blockSize,
          length: (i === this.numberOfWorkers - 1) ? fileSize - (this.numberOfWorkers - 1) * blockSize : blockSize,
          originalFileName: fileName,
          chunkSize: this.chunkSize,
          websocketEndpoint: this.websocketEndpoint,
          file: file,
          workerId: i
        }
      });
    }
  };

  this.stop = function () {
    for (let i = 0; i < workers.length; i++) {
      workers[i].terminate();
    }
  };

  this.onWorkerMessage = function (msg) {
    if (msg.data.cmd === 'WorkerFinished') {
      that.onmessage({
        cmd: 'WorkerInfo',
        workerId: msg.data.workerId,
        param: {
          message: 'Finished.'
        }
      });

      finishedWorkers++;
      if (finishedWorkers === that.numberOfWorkers) {
        that.onmessage({
          cmd: 'Info',
          param: {
            state: 'reassembling',
            message: 'All workers finished. Reassembling...'
          }
        });

        const data = JSON.stringify({
          JobId: jobId,
          TempFileName: tempFileName,
          OriginalFileName: file.name,
          DestinationFolder: that.destination,
          IsOverwrite: isOverwrite,
          Description: fileDescription,
          VidaFileType: vidaFileType,
          JobProgramFileName: jobProgramFileName
        });
        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
        });
        that.http.put(that.reassembleFileEndpoint, data, {
          headers: headers
        }).subscribe(
          (response) => that.onmessage({ cmd: 'ReassembleFinished', virtualRelativeFilePath: response.virtualRelativeFilePath }),
          () => that.onmessage({ cmd: 'ReassembleFailed' })
        );
      }
    } else {
      that.onmessage(msg.data);
    }
  };
}

export class FileDownloadStatus {
  fileName: string;
  status: DownloadStatusEnum;
}

export enum DownloadStatusEnum {
  STARTED = 'started',
  PENDING = 'pending',
  INPROGRESS = 'inprogress',
  DONE = 'done',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  NOTFOUND = 'notfound'
}

export class TaskDownloadStatus {
  constructor (token: string, status: DownloadStatusEnum, files: FileDownloadStatus[], message: string){
    this.taskId = token;
    this.status = status;
    this.message = null;
    this.files = files;
    this.message = message;
  }
  taskId: string;
  status: DownloadStatusEnum;
  message: string;
  files: FileDownloadStatus[];

  public get isFinished(): boolean {
    if (this.status === undefined || this.status === null)
      throw 'Can\'t define task status';
    return [
      DownloadStatusEnum.DONE, 
      DownloadStatusEnum.ERROR,
      DownloadStatusEnum.NOTFOUND
    ].includes(this.status);
  }

  public get isCancelled(): boolean {
    return this.files.findIndex(x => x.status == DownloadStatusEnum.CANCELLED) >= 0;
  }
}

class QResponse<T> {
  statusCode: number;
  result: T;
}
