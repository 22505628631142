import { PumpScheduleStageModel } from './pump-schedule-stage';
import { SpacerMixMethod } from 'libs/constants';

export class PumpSchedule {
  pumpScheduleId: string;
  name: string;
  jobId: string;
  stages: PumpScheduleStageModel[];
  shoeTrackLength: number;
  shoeTrackVolume: number;
  controlPointType: number;
  scheduledShutdown: string;
  targetSafetyFactor: string;
  batchMixingTime: string;
  spacerMixMethod: SpacerMixMethod;
  isManuallySpacerMixMethod: boolean;
  isImportDataFromiCem: boolean;
  isImportDataFromHDF: boolean;
  dryWeight: number;
  dryVolume: number;
  totalCOGS: number;
  linerCirculationMethod: number;
  linerCementLength: number;
  isVersaFlexLiner: boolean;
  order: number;
  icemFileName: string;
  originalPumpScheduleId: string;
  isForceNew: boolean;
  iCemReplaced: boolean;
}
