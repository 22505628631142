import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SlurrySource } from 'apps/vida/src/modules/shared/constant/slurry-source';
import { UnitType } from 'libs/constants';
import { environment } from 'libs/environment';
import { FluidModel, IFactsRequest, IMaterialTypeModel, ISlurryType, ITestType, Job, IFactsRequestInfo, IFactsMaterial, FluidMaterialModel, PumpScheduleStageMaterialModel, ISAPMaterialModel } from 'libs/models';
import { convertWithUnitMeasure, formatNumber, UnitConversionService } from 'libs/ui/unit-conversions';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { IfactService } from './ifact.service';
import { MasterDataService } from './master-data.service';
import { MaterialService } from './material.service';
import { MessageService } from 'primeng/api';

export class UpdateMaterialParameters {
    material: PumpScheduleStageMaterialModel;
    sapMaterial: ISAPMaterialModel;
}

export interface FluidSAPNotificationModel {
    source: any;
    fluid: FluidModel;
}

export class YieldChangedWarningSource {
    constructor(
        public requestId: number = 0,
        public slurryNumber: number = 0,
        public pumpScheduleName: string = '',
        public stageNumber: number = 0,
    ) {}
}

export type YieldChangedWarningSourceGrouped = { [key: string]: YieldChangedWarningSource[] };

@Injectable()
export class FluidService {

    private static readonly THICKENING_TIME_JOB_TYPES = [
        'conductor casing',
        'intermediate casing',
        'production casing',
        'surface casing'
    ];

    public updateMaterial$ = new Subject<UpdateMaterialParameters>();
    public materialMappingUpdated$ = new Subject<any>();
    public changeSAPMaterial$ = new Subject<FluidSAPNotificationModel>();

    public originFluids: FluidModel[] = [];
    private _yieldChangedWarningsSource$ = new BehaviorSubject<YieldChangedWarningSource[]>([]);

    constructor(

        private readonly _httpClient: HttpClient,

        private readonly _materialService: MaterialService,

        private readonly _ifactService: IfactService,

        private readonly _masterDataService: MasterDataService,

        private readonly _unitConversionService: UnitConversionService,

        private readonly messageService: MessageService,
    ) {
    }

    public resetYieldChangedWarningMessagesSource() {
        this._yieldChangedWarningsSource$.next([]);
    }

    public pushYieldChangedWarningMessage(requestId: number, slurryNumber: number, pumpScheduleName: string, stageNumber: number) {
        const existIndex = this._yieldChangedWarningsSource$.value.findIndex(el => el.requestId === requestId
            && el.slurryNumber === slurryNumber
            && el.pumpScheduleName === pumpScheduleName
            && el.stageNumber === stageNumber);

        if (existIndex !== -1) {
            return;
        }

        this._yieldChangedWarningsSource$.next([
            ...this._yieldChangedWarningsSource$.value,
            new YieldChangedWarningSource(requestId, slurryNumber, pumpScheduleName, stageNumber),
        ]);
    }

    public publishYieldChangedWarningMessages() {
        if (this._yieldChangedWarningsSource$.value.length === 0) {
            return;
        }

        const groupedBySlurry = this._yieldChangedWarningsSource$.value.reduce((acc, el) => {
            const groupValue = acc[`${el.requestId}/${el.slurryNumber}`] ? acc[`${el.requestId}/${el.slurryNumber}`] : [];
            return { ...acc, [`${el.requestId}/${el.slurryNumber}`]: [...groupValue, el] }
        }, {} as YieldChangedWarningSourceGrouped);

        Object.keys(groupedBySlurry).forEach(key => {
            const usedIn = groupedBySlurry[key].map(el => `${el.pumpScheduleName}/${el.stageNumber}`).join(', ')
            this.messageService.add({
                life: environment.messagePopupLifetimeMs,
                severity: 'warn',
                detail: `Yield has been changed in iFacts for iFacts ID ${key}, used in Pump Schedule/Stage ${usedIn}.  Material quantities have been recalculated.  Select Save to retain these values.`
            })
        })

        this._yieldChangedWarningsSource$.next([]);
    }

    public static sortFluidForm(aFG: UntypedFormGroup, bFG: UntypedFormGroup) {
        var a = aFG.controls;
        var b = bFG.controls;

        let result = (!a.primaryStatus?.value && !b.primaryStatus?.value) ? 0 :   (a.primaryStatus?.value??'').localeCompare(b.primaryStatus?.value)
        if((!a.primaryStatus?.value && b.primaryStatus?.value) || (a.primaryStatus?.value && !b.primaryStatus?.value))
        {
          result *= -1; //put blank usage at the bottom
        }

        if(result != 0) {
          return result;
        }

        result = FluidService.parseDate(a.createdDate?.value) - FluidService.parseDate(b.createdDate?.value)
        return result;
    }

    public static sortFluid(a: FluidModel, b: FluidModel): number {
        let result = (!a.primaryStatus && !b.primaryStatus) ? 0 :   (a.primaryStatus??'').localeCompare(b.primaryStatus)
        if((!a.primaryStatus && b.primaryStatus) || (a.primaryStatus && !b.primaryStatus))
        {
          result *= -1; //put blank usage at the bottom
        }

        if(result != 0) {
          return result;
        }

        result = FluidService.parseDate(a.createdDate) - FluidService.parseDate(b.createdDate)
        return result;
    }

    private static parseDate(date: string): number {
        if (!date) {
            return 0;
        }
        return Date.parse(date);
    }


    public loadFromIFacts(job: Job, ...fluids: FluidModel[]): Observable<FluidModel[]> {
        if (!fluids || !fluids.length) {
            return of([]);
        }

        fluids = fluids.filter(fluid => fluid);

        return forkJoin(
            this._masterDataService.listMaterialTypes(),
            this._masterDataService.listTestTypes(),
            this._masterDataService.listSlurryTypes(),
            this._ifactService.getRequests(fluids)
        ).pipe(
            map(([materialTypes, testTypes, slurryTypes, requests]) => {
                for (const fluid of fluids) {
                    fluid.testTypeId = undefined; // BUG 428213
                }

                return fluids.map(fluid => this._mapIFactsRequest(
                    job,
                    fluid,
                    requests.find(r => r.selectedSlurry != null ? r.id === +fluid.requestId && r.selectedSlurry.number === +fluid.slurryNo : undefined),
                    materialTypes,
                    testTypes,
                    slurryTypes,
                    job.slurry && job.slurry.find(r => r.requestId === +fluid.requestId && r.slurryNo === +fluid.slurryNo)
                ));
            })
        );
    }

    public buildDisplayName(fluid: FluidModel, testTypes: ITestType[] = []): string {
        // Bug #402213, this condition prevents manually added slurries from being converted properly with another UoM
        // Should be removed completely after regression tests
        // if (fluid.slurrySource === SlurrySource.PumpScheduleFluid) {
        //     return fluid.displayName;
        // }

        const nameParts: string[] = [];

        if (fluid.density) {

            const userUnitsDensity: number = convertWithUnitMeasure(
                fluid.density,
                this._unitConversionService.getApiUnitMeasure(UnitType.Density),
                this._unitConversionService.getCurrentUnitMeasure(UnitType.Density));

            const unitName: string = this._unitConversionService.userUnits.unitSystemSettings
                .find(m => m.unitClass.unitClassEnum === UnitType.Density).unitMeasure.name;

            nameParts.push(`${formatNumber(userUnitsDensity, 2)} ${unitName}`);
        }

        if (fluid.slurrySource === SlurrySource.LinkedFluid) {

            const testType: ITestType = testTypes.find(x => x.id === fluid.testTypeId);
            const testTypeName: string = testType ? testType.name : fluid.testType;

            nameParts.push(testTypeName);
        }

        if (fluid.requestId) {


            let iFactsRequestDisplay: string = `${fluid.requestId}`;
            if (fluid.slurryNo) {

                iFactsRequestDisplay = `${fluid.requestId}/${fluid.slurryNo}`;
            }

            nameParts.push(iFactsRequestDisplay);
        }

        if (fluid.status) {
            nameParts.push(`${fluid.status}`);
        }

        return nameParts.join(', ');
    }

    public getSlurryType(
        fluid: FluidModel,
        request: IFactsRequest,
        slurryTypes: ISlurryType[]): ISlurryType {

        let slurryType = null;

        if (!fluid.slurryTypeId) {
            if (request
                && request.usedFor
                && request.usedFor === 1) {

                const slurryTypeName =
                    `${request.selectedSlurry.type} ${this._materialService.CementType}`;

                slurryType = slurryTypes.find(x => x.name === slurryTypeName);

            } else {

                slurryType = slurryTypes.find(x => x.name === this._materialService.SpacerType);
            }
        } else {

            slurryType = slurryTypes.find(x => x.id === fluid.slurryTypeId);
        }

        return slurryType;
    }

    public moveSupplementalsToAdditives(model: FluidModel): Observable<Boolean> {
        return this._httpClient.put<boolean>(
            `${environment.baseUrl}/api/fluids/move-supplemental-materials`,
            model
        );
    }

    private _mapIFactsRequest(
        job: Job,
        fluidModel: FluidModel,
        request: IFactsRequest,
        materialTypes: IMaterialTypeModel[],
        testTypes: ITestType[],
        slurryTypes: ISlurryType[],
        slurry: FluidModel
    ): FluidModel {
        if (!request) {
            this.updateFluidName(fluidModel, fluidModel.iCemName);
            return fluidModel;
        }

        if (!fluidModel.fluidMaterial) {
            fluidModel.fluidMaterial = [];
        }

        if (!fluidModel.id && !job.isClonedJob) {

            fluidModel = this._createFromRequest(fluidModel, request, testTypes, slurryTypes, job && job.jobTypeName, slurry);
        } else {

            this._updateFromRequest(fluidModel, request, testTypes, slurryTypes, job && job.jobTypeName, slurry);
        }

        fluidModel.fluidMaterial = this._materialService.updateFromRequest(
            fluidModel.fluidMaterial,
            fluidModel.id,
            request,
            materialTypes);

        return fluidModel;
    }

    private updateFluidName(fluid: FluidModel, name: string) {
        if (!fluid.name && name) {
            fluid.name = name;
        }
    }
  
    private getSlurryName(slurry, request, fluidModel) {
        if (slurry) {
            if (fluidModel.iCemName !== null) {
                return request.trademarkName === null || request.trademarkName === '' ? fluidModel.iCemName : request.trademarkName;
            }
            return slurry.name === null ? request.trademarkName : fluidModel.name;
        } else {
            let name = request.trademarkName;
            if (!name) {
                const { selectedSlurry } = request;
                let densityConverted = this._unitConversionService.convertFromSiToApi(selectedSlurry.sg, UnitType.Density);
                const unitName = this._unitConversionService.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === UnitType.Density).unitMeasure.name;
                name = `${formatNumber(densityConverted, 2)}${unitName} ${selectedSlurry.type}`;
            }
            return name;
        }
    }

    private getTempId(slurry, request) {
        if (slurry) {
            return slurry && slurry.tempId || null;
        } else {
            return request.tempId;
        }
    }

    private _createFromRequest(
        fluid: FluidModel,
        request: IFactsRequest,
        testTypes: ITestType[],
        slurryTypes: ISlurryType[],
        jobTypeName: string,
        slurry: FluidModel
    ): FluidModel {
        let newFluid = new FluidModel();
        newFluid.requestId = fluid.requestId;
        newFluid.iCemName = fluid.iCemName;
        newFluid.tempId = this.getTempId(slurry, request);
        newFluid.name = this.getSlurryName(slurry, request, fluid);
        newFluid.slurryNo = fluid.slurryNo;
        newFluid.slurryId = fluid.slurryId;
        newFluid.primaryStatus = fluid.primaryStatus;
        newFluid.isFromVisualizer = fluid.isFromVisualizer;
        newFluid.isFromIFactsBasicSearch = fluid.isFromIFactsBasicSearch;
        newFluid.pumpScheduleNumber = fluid.pumpScheduleNumber;
        newFluid.fluidMaterial = fluid.fluidMaterial;

        if (fluid.testTypeId && fluid.slurryTypeId) {
            newFluid.testTypeId = fluid.testTypeId;
            newFluid.slurryTypeId = fluid.slurryTypeId;
        }

        newFluid = this._updateFromRequest(newFluid, request, testTypes, slurryTypes, jobTypeName, slurry);

        return newFluid;
    }

    private _updateFromRequest(
        fluid: FluidModel,
        request: IFactsRequest,
        testTypes: ITestType[],
        slurryTypes: ISlurryType[],
        jobTypeName: string,
        slurry: FluidModel
    ): FluidModel {

        this._setDefaults(fluid, request);

        if (!fluid.slurryTypeId) {

            const iFactsSlurryType: ISlurryType =
                this.getSlurryType(fluid, request, slurryTypes);

            if (iFactsSlurryType) {

                fluid.slurryTypeId = iFactsSlurryType.id;
                fluid.slurryType = iFactsSlurryType.name;
            }
        }

        if (request.selectedSlurry != null) {            
            if (request.selectedSlurry.id) {

                fluid.slurryId = request.selectedSlurry.id.toString();
                fluid.name = this.getSlurryName(slurry, request, fluid);
            }

            if (request.selectedSlurry.materials) {

                const waterMaterial = request.selectedSlurry.materials.find(f => this._materialService.isWater(f.materialType));

                if (waterMaterial) {

                    fluid.water = waterMaterial.name;
                    fluid.waterDensity = waterMaterial.sg;
                }
            }

            if (request.selectedSlurry.sg) {
                let densityConverted = this._unitConversionService.convertFromSiToApi(request.selectedSlurry.sg, UnitType.Density);
                fluid.density = +formatNumber(densityConverted, 2);
            }

            fluid.isFoam = request.selectedSlurry.isFoam;

            if (request.selectedSlurry.foamDensity) {

                fluid.foamDensity =
                    this._unitConversionService
                        .convertFromSiToApi(request.selectedSlurry.foamDensity, UnitType.Density);
            }

            if (request.selectedSlurry.foamQuality) {

                fluid.foamQuality = request.selectedSlurry.foamQuality;
            }

            fluid.yield = request.selectedSlurry.yieldFT3;
            fluid.waterRequirements = request.selectedSlurry.mixWaterReqGPS;
            fluid.mixWater = request.selectedSlurry.mixWaterReqGPS;
            fluid.mixFluid = request.selectedSlurry.tmfgps;
            fluid.mixVolume = request.selectedSlurry.mixVolume;

            if (!fluid.id) {

                fluid.isCement = request.usedFor === 1;
            }

            fluid.isCementBlend = request.selectedSlurry.isCementBlend;
            fluid.labName = request.labName;
            fluid.blendName = request.selectedSlurry.blendName;
            fluid.sackWeight = request.selectedSlurry.cementBulkDensity;
            fluid.cemmentBulkDensity = request.selectedSlurry.cementBulkDensity;
            fluid.bhst = request.testCondition.bhst;
            fluid.bhct = request.testCondition.bhct;
            fluid.originThickeningTime = request.selectedSlurry.thickeningTime;
        }

        if (jobTypeName
            && FluidService.THICKENING_TIME_JOB_TYPES.includes(jobTypeName.toLowerCase().trim())) {

            fluid.thickeningTime = fluid.originThickeningTime;
        }

        if (!fluid.testTypeId && fluid.requestInfo && fluid.requestInfo.type) {
            if (fluid.testType) {
                const { id, name } = testTypes.find(x => x.name === fluid.testType);

                if (id && name) {
                    fluid.testTypeId = id;
                    fluid.testType = name;
                }
            } else {

                const { id, name } = testTypes.find(x => x.name === fluid.requestInfo.type);

                if (id && name) {
                    fluid.testTypeId = id;
                    fluid.testType = name;
                }
            }
        }

        fluid.displayName = this.buildDisplayName(fluid, testTypes);

        return fluid;
    }

    private _setDefaults(fluid: FluidModel, request: IFactsRequest): void {

        fluid.slurrySource = SlurrySource.LinkedFluid;
        fluid.slurryId = null;
        fluid.water = null;
        fluid.waterDensity = null;
        fluid.density = null;
        fluid.isFoam = false;
        fluid.foamDensity = null;
        fluid.foamQuality = null;
        fluid.yield = null;
        fluid.waterRequirements = null;
        fluid.mixWater = null;
        fluid.mixFluid = null;
        fluid.mixVolume = null;
        fluid.isCementBlend = false;
        fluid.labName = null;
        fluid.blendName = null;
        fluid.sackWeight = null;
        fluid.cemmentBulkDensity = null;
        fluid.bhst = null;
        fluid.bhct = null;
        fluid.thickeningTime = null;
        fluid.originThickeningTime = null;
        fluid.requestInfo = new IFactsRequestInfo(request.id, request.type, request.status, request.trademarkName, request.availableSlurries);
        fluid.status = request.status;
        fluid.testStatus = request.status;
    }
}
