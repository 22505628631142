<div [formGroup]="stageInfoForm" [loadingIndicator]="loadingSubscription" class="ui-table w-100">
  <table>
    <thead>
      <tr class="stage-info-header">
        <th width="175px">Type <span *ngIf="isScheduleEditView">*</span></th>
        <th>
          <span *ngIf="isMud$ | async" [lockableElement]="'pumpSchedule_btnMudParamEdit'">
            Mud parameters
            <a *ngIf="isMudEditable" (click)="openMudParameters()"
               [class.disabled]="isFluidChangeDisabled$ | async">(Edit)</a>
          </span>
          <span *ngIf="isFluid$ | async">
            Fluid
            <a *ngIf="isIFactsFluid$ | async" href="{{ slurryId$ | async | iFactsLink:'slurry'}}" target="_blank">
              (View in iFacts)
            </a>
            <div [lockableElement]="'job_completed'" class="link-fluid">
              <a *ngIf="isShowFluidLink" (click)="linkFluids()" title="Link Fluid Request ID"
                 [class.disabled]="isFluidLinkDisable$ | async">
                <span class="link-text">Link Fluid Request ID</span>
              </a>
            </div>
          </span>
        </th>
        <th [class]="{ 'fluid-name-col-cp1': _viewState.isCP1View, 'fluid-name-col-cp2': _viewState.isCP2View }"
            *ngIf="showFluidOnCP$('fluidName') | async">Name</th>
        <th *ngIf="isVisible$('avgRate') | async" width="90px"><span *ngIf="isNotPlug$ | async">Planned Rate</span></th>
        <th *ngIf="isVisible$('volume') | async" width="110px">
          <span *ngIf="isNotPlug$ | async">Planned Quantity</span>
        </th>
        <th *ngIf="isVisible$('topOfFluid') | async" width="90px">
          <span *ngIf="isNotPlug$ | async">Top of Fluid</span>
        </th>
        <th *ngIf="isVisible$('loadOutQuantity') | async" width="100px">
          <span *ngIf="isNotPlug$ | async">
            {{
            (isOffshoreJob$ | async) ? 'Required on Rig' : 'Load Out Quantity'
            }}
          </span>
        </th>

        <th *ngIf="(isVisible$('iFactsActual') | async) && (isCementOrSpacer$ | async)" width="110px">
          <span *ngIf="isNotPlug$ | async">Actual iFacts ID</span><br/>
          <a *ngIf="isNotPlug$ | async" (click)="linkFluidsActual()" title="Link Fluid Request ID" [class.disabled]="isFluidLinkDisable$ | async">
            <span class="link-text">Link Fluid Request ID</span>
          </a>
        </th>

        <th *ngIf="isVisible$('actualVolumePumped') | async" width="100px">
          <span *ngIf="isNotPlug$ | async">
            Actual
            Quantity<span *ngIf="isRequiredShow">*</span>
          </span>
        </th>
        <th *ngIf="isVisible$('dryWeight') | async" width="100px">
          <span *ngIf="isNotPlug$ | async">Dry Weight</span>
        </th>
        <th *ngIf="isVisible$('dryVolume') | async" width="135px">
          <span *ngIf="isNotPlug$ | async">Dry Volume</span>
        </th>
        <th *ngIf="isVisible$('plannedDensity') | async" width="110px">
          <span *ngIf="isNotPlug$ | async">
            Planned
            Density
          </span>
        </th>
        <th *ngIf="isVisible$('actualDensity') | async" width="110px">
          <span *ngIf="isNotPlug$ | async">
            Actual
            Density<span *ngIf="isRequiredShow">*</span>
          </span>
        </th>
        <th *ngIf="isVisible$('totalCOGS') | async" width="90px"><span *ngIf="isNotPlug$ | async">Fluid COGS</span></th>
        <th *ngIf="isScheduleEditView" width="150px"></th>
        <th *ngIf="(isVisible$('totalPlannedCO2e') | async) && hasScope3access" width="90px">Total CO2e</th>
        <th *ngIf="(isVisible$('totalActualCO2e') | async) && hasScope3access" width="90px">Actual CO2e</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="isScheduleEditView; else readonlyStageBodyTemplate" class="stage-info-body"
          [formGroup]="stageInfoForm">
        <td [lockableElement]="'PumpSchedule_pumpScheduleFluidTypeId'" class="vertical-align-middle">
          <p-dropdown formControlName="pumpScheduleFluidTypeId" placeholder="Enter a Fluid Value" showClear="true"
                      filter="true" [options]="stageTypeItems$ | async">
          </p-dropdown>
          <error-display formControlName="pumpScheduleFluidTypeId" [errorMsg]="errorMessages.pumpScheduleFluidType">
          </error-display>
        </td>
        <td class="vertical-align-middle">
          <span *ngIf="isMud$ | async">{{ mudParameterDisplay$ | async }}</span>
          <div *ngIf="isFluid$ | async" [lockableElement]="'PumpSchedule_slurry'" class="pump-schedule-slurry">            
            <typeahead-datatable
              placeholder="Search fluid..."
              [columns]="fluidColumns"
              [options]="fluidsAvailable"
              [showOptionsOnFocus]="true"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="selectedFluidName"
              (onLazyLoad)="onFluidLazyLoad($event)"
              (onChange)="onFluidChange($event)"
              (onClear)="onFluidClear()">
            </typeahead-datatable>
            <error-display formControlName="slurry" [errorMsg]="errorMessages.pumpScheduleLinkedFluid"></error-display>
          </div>
        </td>
        <td class="vertical-align-middle" *ngIf="isFluid$ | async">
          <div [ngClass]="{disabled: !(slurryName$ | async) }" [lockableElement]="'PumpSchedule_slurry'">
            <input type="text" customTooltip (keyup)="changeFluidName($event)" [valueToParse]="fluidName"
                   formControlName="fluidName" class="fluid-name">
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="isNotPlug$ | async" class="display-measure">
            <span formControlName="avgRate" dUnitValue={{UnitType.FlowRate}} dUnitWithName="true"></span>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="isNotPlug$ | async" class="display-measure">

            <span formControlName="plannedVolume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="isNotPlug$ | async" class="display-measure">
            <span formControlName="topOfFluid" dUnitValue={{UnitType.Distance}} dUnitWithName="true"></span>
          </div>
        </td>
        <td *ngIf="isVisible$('actualVolumePumped') | async" class="vertical-align-middle">
          <div *ngIf="isNotPlug$ | async" class="display-measure">
            <span formControlName="actualVolumePumped" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
          </div>
        </td>
        <td class="vertical-align-middle">
          <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="dryWeight$ | async"
                [unitType]="UnitType.Weight"></span>
        </td>
        <td class="vertical-align-middle">
          <ng-contaier *ngIf="isNotPlug$ | async">
            <div class="unit-value" [apiValue]="dryVolume$ | async" [unitType]="UnitType.DryVolume"></div>
            <div *ngIf="anyMaterialMissingBulkDensity$ | async">
              <span class="warning pr-1">Not all Bulk Densities available</span>
              <span class="fa fa-question-circle icon-help pl-1" (click)="showMissingBulkDensityHelp()"></span>
            </div>
          </ng-contaier>
        </td>
        <td *ngIf="isVisible$('totalCOGS') | async" class="vertical-align-middle">
          <ng-container *ngIf="isNotPlug$ | async">
            <div *ngIf="!(cogsAvailable$ | async)">
              <span class="warning pr-1">Not all COGS available</span>
              <span class="fa fa-question-circle icon-help pl-1" (click)="showCogsHelp()"></span>
            </div>
            <span *ngIf="cogsAvailable$ | async" class="unit-value">${{ cogs$ | async | number: '.2-2' }}</span>
          </ng-container>
        </td>
        <td class="vertical-align-middle">
          <ul class="list-inline">
            <li class="list-inline-item" [lockableElement]="'PumpSchedule_addStage'">
              <a *ngIf="!isStageRemovable" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false" aria-hidden="true">
                Add Stage
              </a>
              <a *ngIf="isStageRemovable" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false" aria-hidden="true">
                Add/ Remove Stage
              </a>
              <ul *ngIf="isScheduleEditView" class="dropdown-menu">
                <li [ngClass]="{disabled: !isStageRemovable}" *ngIf="isPumpDefault">
                  <a *ngIf="isStageRemovable" (click)="insertStageBefore()">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                  <a *ngIf="!isStageRemovable">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                </li>
                <li *ngIf="!isPumpDefault">
                  <a (click)="insertStageBefore()">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                </li>
                <li>
                  <a (click)="insertStageAfter()">
                    <i class="fa fa-plus"></i> Insert Stage After
                  </a>
                </li>
                <li [ngClass]="{disabled: !isStageRemovable}" *ngIf="isPumpDefault">
                  <a *ngIf="isStageRemovable" (click)="deleteStage()">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                  <a *ngIf="!isStageRemovable">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                </li>
                <li [ngClass]="{disabled: !(isFirstStageRemovable | async)}" *ngIf="!isPumpDefault">
                  <a *ngIf="(isFirstStageRemovable | async)" (click)="deleteStage()">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                  <a *ngIf="!(isFirstStageRemovable | async)">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>
      <ng-template #readonlyStageBodyTemplate>
        <tr>
          <td class="vertical-align-middle">
            <span>{{ typeName$ | async }}</span>
          </td>
          <td class="vertical-align-middle">
            <span *ngIf="isMud$ | async">{{ mudParameterDisplay$ | async }}</span>
            <div *ngIf="isFluid$ | async" [lockableElement]="'PumpSchedule_selectedFluidId'">
              <typeahead-datatable 
                *ngIf="isFluidSelectable"
                [disabled]="isFluidLinkDisable$ | async"
                placeholder="Search fluid..."
                [columns]="fluidColumns"
                [options]="fluidsAvailable"
                [showOptionsOnFocus]="true"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="selectedFluidName"
                (onLazyLoad)="onFluidLazyLoad($event)"
                (onChange)="onFluidChange($event)"
                (onClear)="onFluidClear()">
              </typeahead-datatable>
              <div *ngIf="!isFluidSelectable">
                <span class="unit-value">{{ slurryName$ | async }}</span>
              </div>
            </div>
          </td>
          <td class="vertical-align-middle" *ngIf="showFluidOnCP$('fluidName') | async">
            <div [lockableElement]="'PumpSchedule_slurry'">
              <input type="text" customTooltip (keyup)="changeFluidName($event)" [valueToParse]="fluidName"
                     formControlName="fluidName" class="fluid-name">
            </div>
          </td>
          <td *ngIf="isVisible$('avgRate') | async" class="vertical-align-middle">
            <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="avgRate$ | async"
                  [unitType]="UnitType.FlowRate"></span>
          </td>
          <td *ngIf="isVisible$('volume') | async" class="vertical-align-middle">
            <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="plannedVolume$ | async"
                  [unitType]="UnitType.LargeVolume"></span>
          </td>
          <td *ngIf="isVisible$('topOfFluid') | async" class="vertical-align-middle">
            <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="topOfFluid$ | async"
                  [unitType]="UnitType.Distance"></span>
          </td>
          <td *ngIf="isVisible$('loadOutQuantity') | async" class="vertical-align-middle">
            <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="loadOutQuantity$ | async"
                  [unitType]="UnitType.LargeVolume"></span>
          </td>

          <td *ngIf="(isVisible$('iFactsActual') | async) && (isCementOrSpacer$ | async)" class="vertical-align-middle">
            <a *ngIf="(isNotPlug$ | async) && stageInfoForm.value.actualSlurry" href="{{ stageInfoForm.value.actualSlurry.slurryId | iFactsLink:'slurry'}}" target="_blank">
              {{stageInfoForm.value.actualSlurry.requestId}}/{{stageInfoForm.value.actualSlurry.slurryNo}}
            </a>
          </td>

          <td *ngIf="isVisible$('actualVolumePumped') | async" class="vertical-align-middle">
            <ng-container *ngIf="isNotPlug$ | async">
              <span *ngIf="!isActualVolumeEditable" class="unit-value" [apiValue]="actualVolumePumped$ | async"
                    [unitType]="UnitType.LargeVolume">
              </span>
              <div *ngIf="isActualVolumeEditable" class="flx-ctn-row"
                   [lockableElement]="'fluidDetailInfo_actualVolume'">
                <input-unit class="flx-itm-grow m-r-10" formControlName="actualVolumePumped"
                            unit={{UnitType.LargeVolume}} (focusout)="onFocusOut($event.target)" pKeyFilter="pnum" maxlength="13"></input-unit>
                <span class="flx-itm-center" dUnitName={{UnitType.LargeVolume}}></span>
              </div>
            </ng-container>
          </td>
          <td *ngIf="isVisible$('dryWeight') | async" class="vertical-align-middle">
            <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="dryWeight$ | async"
                  [unitType]="UnitType.Weight"></span>
          </td>
          <td *ngIf="isVisible$('dryVolume') | async" class="vertical-align-middle">
            <ng-container *ngIf="isNotPlug$ | async">
              <div class="unit-value" [apiValue]="dryVolume$ | async" [unitType]="UnitType.DryVolume"></div>
              <div *ngIf="anyMaterialMissingBulkDensity$ | async">
                <span class="warning pr-1">Not all Bulk Densities available</span>
                <span class="fa fa-question-circle icon-help pl-1" (click)="showMissingBulkDensityHelp()"></span>
              </div>
            </ng-container>
          </td>
          <td *ngIf="isVisible$('plannedDensity') | async" class="vertical-align-middle">
            <span *ngIf="isNotPlug$ | async" class="unit-value" [apiValue]="plannedDensity$ | async"
                  [unitType]="UnitType.Density"></span>
          </td>
          <td *ngIf="isVisible$('actualDensity') | async" class="vertical-align-middle">
            <div *ngIf="isActualDensityEditable$ | async">
              <div class="flx-ctn-row" [lockableElement]="'fluidDetailInfo_actualDensity'">
                <input-unit class="flx-itm-grow m-r-10" formControlName="actualDensity" unit={{UnitType.Density}} pKeyFilter="pnum" maxlength="13">
                </input-unit>
                <span class="flx-itm-center" dUnitName={{UnitType.Density}}></span>
              </div>
            </div>
          </td>
          <td *ngIf="isVisible$('totalCOGS') | async" class="vertical-align-middle">
            <div *ngIf="!(cogsAvailable$ | async)">
              <span class="warning pr-1">Not all COGS available</span>
              <span class="fa fa-question-circle icon-help pl-1" (click)="showCogsHelp()"></span>
            </div>
            <span *ngIf="cogsAvailable$ | async" class="unit-value">${{ cogs$ | async | number: '.2-2' }}</span>
          </td>

          <td *ngIf="(isVisible$('totalPlannedCO2e') | async) && hasScope3access" class="vertical-align-middle">

            <span *ngIf="(plannedScopeWithBlendCo2e$ | async) > 0" class="unit-value" [apiValue]="plannedScopeWithBlendCo2e$ | async"
                  [unitType]="UnitType.Weight" [formatNum]="0" [shouldShowFormatNum]="true"></span>

          </td>
          <td *ngIf="(isVisible$('totalActualCO2e') | async) && hasScope3access" class="vertical-align-middle">
            <span *ngIf="(actualScopeWithBlendCo2e$ | async) > 0" class="unit-value" [apiValue]="actualScopeWithBlendCo2e$ | async"
                  [unitType]="UnitType.Weight" [formatNum]="0" [shouldShowFormatNum]="true"></span>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div> 

<p-dialog [(visible)]="hasChanges" [responsive]="true" [draggable]="false" styleClass="dialog-custom"
  [style]="{width: '600px', height: '300px'}" [modal]="true" [dismissableMask]="true" [closable]="false">
  <p-header style="font-size: 24px">
    Confirmation
    <span class="pi pi-times close-custom" (click)="onCancel()"></span>
  </p-header>
  <form class="dialog-message" style="font-size: 14px">
    <div *ngIf="loadingStatus !== null; else noPushStatus">
      <span>
        This fluid has already been pushed to the Bulk Plant and current Load Status is {{ loadingStatus }}. 
        If you wish to change this fluid, be sure to communicate the change to the Bulk Plant and all involved parties.<br /><br />
        Do you wish to make this change?
      </span>
    </div>
    <ng-template #noPushStatus>
      <span>
        This Fluid has already been loaded in the Bulk Plant. Making this change may require reblending of the load.
        Simple volume increases may be updated in CP 2, but any other change requires that CP 1 be unlocked and recompleted,
        including all changes, with required approvals. Also consider cost implications and accountabilities.<br /><br />
        Are you sure you want to make this change?
      </span>
    </ng-template>
  </form>
  <div>
    <div style="float:left">
      <button [autofocus]="true" class="btn cancel-btn" (click)="onConfirm()">Yes</button>
    </div>
    <div style="float:right">
      <button class="btn primary-btn dialog-button" (click)="onCancel()">NO</button>
    </div>
  </div>
</p-dialog>