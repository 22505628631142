import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';
import { RoleLevels } from 'apps/vida/src/modules/application/models';
import { PlantManagementModel } from 'libs/models/administrations/plant-management.model';
import { BulkBoardPlantModel } from 'libs/models/administrations/bulk-board-plant.model';

@Injectable()
export class PlantManagementService {

  onBulkBoardStatusChanged$ = new Subject<any>();

  constructor(private http: HttpService) { }

  getGroups(minRoleLevel: number = RoleLevels.Reviewer) {
    return this.http.Get(`${environment.baseUrl}/api/admin/region-groups?minRoleLevel=${minRoleLevel}`);
  }

  updatePlantManagement(model: PlantManagementModel): Observable<boolean> {
    const url = `${environment.baseUrl}/api/plant-management`;
    const stringifyModel = JSON.stringify(model);

    return this.http.Put(url, stringifyModel);
  }

  createPlantManagement(model: PlantManagementModel): Observable<any> {
    const url = `${environment.baseUrl}/api/plant-management`;
    const stringifyModel = JSON.stringify(model);

    return this.http.Post(url, stringifyModel);
  }

  getPlantManagementByGroupId(groupId: string): Observable<PlantManagementModel> {
    return this.http.Get(`${environment.baseUrl}/api/plant-management/${groupId}`);
  }

  getBulkBoardStatusByGroupId(groupId: string): Observable<boolean>{
    return this.http.Get(`${environment.baseUrl}/api/plant-management/bulk-board-status/${groupId}`);
  }

  getAccessToBBlink(groupId: string) {
    return  this.http.Get<boolean>(`${environment.baseUrl}/api/plant-management/bulk-board-link-enabled/${groupId}`);
  }

  getAllBulkBoardsGroups(groupId: string): Observable<BulkBoardPlantModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/admin/groups-bulk-boards/${groupId}`);
  }
  checkUserHasBulkBoardPushAccess(groupId: string): Observable<boolean> {
    return this.http.Get(`${environment.baseUrl}/api/admin/check-bb-push-access/${groupId}`);
  }
}
